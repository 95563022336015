import React from 'react';
import {
  Center,
  Card,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Heading,
  Text,
  GridItem
} from "@chakra-ui/react";
import '../../styles/Home/FAQs.css';  // Assuming the CSS is in a file named FAQs.css

const FAQs = () => {
  const faqItems = [
    {
      question: "How does Sugriva.in ensure high standards for candidates?",
      answer: "We conduct frequent performance reviews to ensure that our candidates maintain high standards. Candidates not meeting expectations are temporarily de-listed.",
    },
    {
      question: "How can Sugriva.in streamline our hiring process?",
      answer: "Sugriva.in provides a single, highly qualified candidate for each open role through our rigorous screening process, allowing you to focus on interviewing and hiring the best candidate without the hassle of searching through candidate pools.",
    },
    {
      question: "How does the rapid deployment for urgent needs work?",
      answer: "We offer quick hires for urgent deliverables, with on-bench candidates available for immediate start. These candidates work on projects until completion or until a full-time employee is hired, ensuring timely delivery without delays in hiring.",
    },
    {
      question: "Who can I contact for more information or inquiries?",
      answer: "For any inquiries related to our services or to discuss potential partnerships, you can contact our Chief Recruitment and Business Officer, Shikha. We are happy to answer any questions you may have.",
    },
  ];

  return (
    <GridItem id="faqs" area={'faqs'} className="faqs-section"> {/* Apply the class here */}
      <Center pt={['1rem', '5rem']} pb={['1rem', '5rem']}>
        <Heading size={["lg", "2xl"]}>
          Frequently Asked Questions
        </Heading>
      </Center>
      <Center>
        <Card width="75vw" mx="auto" borderRadius="md" overflow="hidden">
          <Accordion allowToggle>
            {faqItems.map((item, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box p='5' as='span' flex='1' textAlign='left'>
                      <Text fontSize={["lg", "xl"]}>
                        {item.question}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text fontSize={["md", "lg"]}>
                    {item.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Card>
      </Center>
    </GridItem>
  );
};

export default FAQs;