import React from 'react';
import { Center, Heading, Text, Box } from "@chakra-ui/react";
import '../../styles/Home/CTABanner.css'; // Assuming the CSS is in a file named CTABanner.css

const CTABanner = () => {
  return (
    <Box className='cta-banner'>
      <Center flexDirection="column" h='100%'>
        <Heading size={['lg', '2xl']} color="white" textAlign="center" mb={4}>
          Your Next Great Hire Awaits!
        </Heading>
        <Text fontSize={['xl', '3xl']} color="white" textAlign="center">
          Ready to Transform Your Team? We are just a Call Away.
        </Text>
      </Center>
    </Box>
  );
};

export default CTABanner;