import React, { useState } from 'react';
import { Box, Grid, GridItem, Text, Button } from '@chakra-ui/react';
import '../../styles/Dashboard/Dashboard.css'; // Import specific CSS for the dashboard
import Sidebar from './Sidebar'; // Import the Sidebar component
import JobTile from './JobTile'; // Import the JobTile component
import HireCandidatePage from './HireCandidatePage'; // Import the HireCandidatePage component

import logo from '../../assets/logo-white.png';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showHirePage, setShowHirePage] = useState(false); // New state for toggling views

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleHireClick = () => {
    setShowHirePage(true); // Show the Hire a Candidate page
  };

  return (
    <Box className="dashboard-container">
      <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap={0} className="main-grid">
        <GridItem>
          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
        </GridItem>
        <GridItem className="content">
          {!showHirePage && (
            <Box className="header-bar">
              {/* Logo will only be visible on mobile */}
              <img src={logo} alt="Logo" className="logo-img" onClick={toggleSidebar} />
              <Text fontSize="2xl" className="page-title">Jobs</Text>
              <Button className="hire-button-desktop" onClick={handleHireClick}>Hire a Candidate</Button>
              <Button className="hire-button" onClick={handleHireClick}>
                <span className="plus-icon">+</span> {/* Use + icon on mobile */}
              </Button>
            </Box>
          )}

          {showHirePage ? (
            <HireCandidatePage logo={logo} toggleSidebar={toggleSidebar} /> // Pass logo and toggleSidebar as props
          ) : (
            <div>
              <div className="search-bar">
                <input type="text" placeholder="Search jobs..." className="search-input" />
              </div>
              <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6} className="job-cards">
                <JobTile location="San Francisco, CA" status="Active" />
                <JobTile location="New York, NY" status="Not-Active" />
                <JobTile location="New York, NY" status="Active" />
                <JobTile location="New York, NY" status="Active" />
              </Grid>
            </div>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Dashboard;