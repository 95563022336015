import React, { useState } from 'react';
import { Box, Text, Input, Button, Grid, Select, useToast } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons'; // Import PhoneIcon from Chakra UI
import '../../styles/Dashboard/HireCandidatePage.css';

const HireCandidatePage = ({ logo, toggleSidebar }) => {
  const [currentStep, setCurrentStep] = useState(1); // State to track current step
  const [jobTitle, setJobTitle] = useState(''); // State for job title
  const [experience, setExperience] = useState(''); // State for experience
  const toast = useToast(); // Toast for showing messages

  const handleNextClick = () => {
    if (currentStep === 1 && (!jobTitle || !experience)) {
      toast({
        title: "Incomplete Details",
        description: "Please enter a job title and select experience.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Move to the next step
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <Box className="hire-candidate-page">
      <img src={logo} alt="Logo" className="logo-img" onClick={toggleSidebar} />
      <Text fontSize="3xl" className="hire-title" mb={6}>
        Hire a Candidate
      </Text>

      {/* Progress Indicator */}
      <Grid className="progress-indicator" templateColumns="repeat(3, 1fr)" gap={4} alignItems="center" mb={8}>
        <Box className={`step ${currentStep >= 1 ? 'completed' : ''}`}>
          <div className={`circle ${currentStep === 1 ? 'current-step' : ''}`}>
            <div className={currentStep === 1 ? "inner-circle" : ""}></div>
          </div>
          <Text className="step-label">Job details</Text>
        </Box>
        <Box className={`step ${currentStep >= 2 ? 'completed' : ''}`}>
          <div className={`circle ${currentStep === 2 ? 'current-step' : ''}`}>
            <div className={currentStep === 2 ? "inner-circle" : ""}></div>
          </div>
          <Text className="step-label">JD Enhancements</Text>
        </Box>
        <Box className={`step ${currentStep >= 3 ? 'completed' : ''}`}>
          <div className={`circle ${currentStep === 3 ? 'current-step' : ''}`}>
            <div className={currentStep === 3 ? "inner-circle" : ""}></div>
          </div>
          <Text className="step-label">Searching a match</Text>
        </Box>
      </Grid>

      {/* Conditional Rendering Based on Current Step */}
      {currentStep === 1 && (
        <Box className="form-container">
          <Box className="form-group">
            <label htmlFor="job-title">Job Title</label>
            <Input
              id="job-title"
              placeholder="Software Engineer"
              mb={4}
              variant="unstyled"
              className="input-field"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </Box>
          <Box className="form-group">
            <label htmlFor="experience">Experience</label>
            <Select
              id="experience"
              placeholder="Select experience"
              mb={4}
              className="select-field"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            >
              {[...Array(11)].map((_, i) => (
                <option key={i} value={i}>{i} year{i !== 1 ? 's' : ''}</option>
              ))}
            </Select>
          </Box>
          <Button className="next-button" onClick={handleNextClick}>Next</Button>
        </Box>
      )}

      {currentStep === 2 && (
        <Box className="jd-enhancements">
          <Text fontSize="xl" mb={4} textAlign="center">
            Let’s connect and craft a stellar job post for the next big thing in software engineering!
          </Text>
          <Box className="form-group">
            <label htmlFor="callback">Get a callback immediately</label>
            <Input
              id="callback"
              placeholder="+91"
              mb={4}
              variant="unstyled"
              className="input-field"
            />
          </Box>
          <Text fontSize="lg" mb={2} textAlign="center">Or schedule a call for later</Text>
          <Box className="form-group">
            <Input
              type="date"
              mb={4}
              variant="unstyled"
              className="input-field"
            />
            <Select
              id="time"
              placeholder="Select time"
              mb={4}
              className="select-field"
            >
              {Array.from({ length: 12 }, (_, i) => i + 9).map(hour => (
                <option key={hour} value={`${hour}:00`}>{`${hour}:00`}</option>
              ))}
            </Select>
          </Box>
          <Button className="next-button" onClick={handleNextClick}>Submit</Button>
        </Box>
      )}

      {currentStep === 3 && (
        <Box className="searching-match">
          <PhoneIcon boxSize={12} mb={4} /> {/* Phone Icon */}
          <Text fontSize="xl" mb={4} textAlign="center">
            Expect a call shortly!
          </Text>
          <Text fontSize="lg" mb={2} textAlign="center">
            We’ll ring you up in a few minutes to discuss your requirements for a perfect candidate match.
          </Text>
          <Button className="next-button" onClick={() => alert('Proceeding to Next Step')}>Hire more Candidates</Button>
        </Box>
      )}
    </Box>
  );
};

export default HireCandidatePage;