import React from 'react';
import './styles.css';

import '@typeform/embed/build/css/widget.css';
import { ChakraProvider, Grid, GridItem } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Navbar from "./components/Home/navbar.js"; 
import RecruitmentSection from './components/Home/RecruitmentSection.js';
import QualitySlides from './components/Home/QualitySlides.js';
import FAQs from './components/Home/FAQs.js';
import Footer from './components/Home/Footer.js';
import CTABanner from './components/Home/CTABanner.js';
import Dashboard from './components/Dashboard/Dashboard.js'; // Import Dashboard component
import CodeEditor from './components/Editor/Editor.js';

function MyComponent() {
  return (
    <ChakraProvider>
      <Router>
        <MainContent />
      </Router>
    </ChakraProvider>
  );
}

const MainContent = () => {
  const location = useLocation(); // Use hook to get the current location

  return (
    <>
      {/* Conditionally render Navbar based on the current route */}
      {location.pathname !== '/dashboard' && <Navbar />} {/* Exclude Navbar on Dashboard */}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/editor" element={<CodeEditor/>} />  
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

// Home page component to render initial content
const HomePage = () => (
  <Grid
    templateAreas={`"header header"
                    "nav nav"
                    "main main"
                    "faqs faqs"
                    "cta-banner cta-banner"
                    "contact contact"
                    "footer footer"`}
    templateColumns="1fr"  // Single column for full-width sections
    gap="0"
    color="blackAlpha.700"
    minH="100vh"
  >
    <GridItem position='relative' w='100%' area={'nav'}>
      <RecruitmentSection />
    </GridItem>
    <GridItem id="features" area={'main'}>
      <QualitySlides />
    </GridItem>
    <GridItem area={'faqs'}>
      <FAQs />
    </GridItem>
    <GridItem area={'cta-banner'}>
      <CTABanner />
    </GridItem>
    <GridItem area={'footer'}>
      <Footer />
    </GridItem>
  </Grid>
);

export default MyComponent;