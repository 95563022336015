import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 
import "../../styles/Home/LoginPopup.css"; 

const LoginPopup = ({ isOpen, onClose, onLoginSuccess }) => {
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      console.log("JWT token found, user is already logged in.");
      onClose(); 
      return;  // Exit early if token is found
    }

    const initializeGoogleSignIn = () => {
      if (window.google) {
        console.log("Initializing Google Sign-In...");
        window.google.accounts.id.initialize({
          client_id: "538508442865-molqloil4glgesvv1v7nc8csbd19ekj2.apps.googleusercontent.com",
          callback: handleCredentialResponse,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin"),
          { theme: "outline", size: "large" }
        );
        console.log("Google Sign-In button rendered.");
      } else {
        console.error("Google object not found on window. Initialization failed.");
      }
    };

    if (!window.google) {
      console.log("Google API script not found, loading script...");
      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        console.log("Google API script loaded successfully.");
        initializeGoogleSignIn();
      };
      script.onerror = () => {
        console.error("Failed to load the Google API script.");
      };
      document.head.appendChild(script);
    } else {
      initializeGoogleSignIn();
    }
  }, [navigate, onClose]);

  const handleCredentialResponse = async (response) => {
    console.log("handleCredentialResponse called with response:", response);
    if (response.credential) {
      try {
        // Send the Google credential to the backend for processing
        const res = await fetch('https://sugriva.in/api/google-signin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ credential: response.credential }),
        });

        const data = await res.json();
        if (res.ok) {
          // Store JWT token in local storage
          localStorage.setItem('jwtToken', response.credential);
          console.log("JWT token stored successfully.");
          alert("Login successful!");

          // Call the onLoginSuccess callback if provided
          if (onLoginSuccess && typeof onLoginSuccess === 'function') {
            onLoginSuccess();
          }

          onClose(); 
        } else {
          console.error("Login failed:", data.error);
          alert("Login failed. Please try again.");
        }
      } catch (e) {
        console.error("Error during login process:", e);
        alert("An error occurred while logging in. Please try again.");
      }
    } else {
      console.log("Sign-in failed. No credential received.");
      alert("Invalid credentials. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="login-popup-overlay" onClick={onClose}>
      <div className="login-popup" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Log In or Sign Up</h2>
        <p>Get started for free</p>
        <div id="g_id_onload"></div>
        <div id="g_id_signin" data-type="standard"></div>

        <p className="terms">
          By clicking "Sign In with Google" you agree to our{" "}
          <a href="#terms">Terms of Service</a> and{" "}
          <a href="#privacy">Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
};

export default LoginPopup;