import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import logo from "../../assets/img.png"; // Ensure the logo path is correct
import "../../styles/Home/navbar.css"; // Import custom CSS for additional styles
import LoginPopup from "./LoginPopup"; // Import the LoginPopup component

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const openLoginPopup = () => {
    setShowLoginPopup(true);
  };

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
    const token = localStorage.getItem('jwtToken');
    if (token) {
      setIsLoggedIn(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setIsLoggedIn(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo-container" onClick={toggleDropdown}>
          <img src={logo} alt="Sugriva Logo" className="navbar-logo" />
        </div>
        <ul className={`nav-links ${showDropdown ? "active" : ""}`}>
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#employers">For Employers</a>
          </li>
          <li>
            <a href="#candidates">For Candidates</a>
          </li>
          <li>
            {isLoggedIn ? (
              <Button className="login-btn" variant="outline" onClick={handleLogout}>
                Log Out
              </Button>
            ) : (
              <Button className="login-btn" variant="outline" onClick={openLoginPopup}>
                Log In
              </Button>
            )}
          </li>
        </ul>
      </div>
      <LoginPopup isOpen={showLoginPopup} onClose={closeLoginPopup} />
    </nav>
  );
};

export default Navbar;