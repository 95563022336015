// src/components/Dashboard/Icons.js

import React from 'react';

export const HomeIcon = () => (
  <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8.707.293a1 1 0 00-1.414 0L1 6.586V14a2 2 0 002 2h3a2 2 0 002-2V9a1 1 0 012 0v5a2 2 0 002 2h3a2 2 0 002-2V6.586l-6.293-6.293z" />
  </svg>
);

export const DashboardIcon = () => (
  <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M3 0a2 2 0 00-2 2v10a2 2 0 002 2h2v-2H3V2h2V0H3zm10 0h-2v2h2v10h-2v2h2a2 2 0 002-2V2a2 2 0 00-2-2zm-3 5a2 2 0 00-2 2v6a2 2 0 002 2h2v-2h-2V7h2V5h-2zM7 7v6h2V7H7zm0-7H5v2h2V0zm0 4H5v2h2V4zm4 4h-2v2h2V8zm-4 0H5v2h2V8zm0 4H5v2h2v-2z" />
  </svg>
);

export const JobsIcon = () => (
  <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M6 8.5a.5.5 0 01.5-.5h1v-1H4v7a2 2 0 002 2h8a2 2 0 002-2V7h-3.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H15V3h-2.5A1.5 1.5 0 0011 4.5v1.496h-1.5a.5.5 0 00-.5.5v3.5H6.5a.5.5 0 01-.5-.5V8.5zm2.5.5h1.5v1h-1v-1z" />
  </svg>
);

export const CMIIcon = () => (
  <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 1.5a.5.5 0 01.5.5v1h1.086A1.5 1.5 0 0111 3.914V5h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H11v1.086A1.5 1.5 0 019.586 9H8.5v1a.5.5 0 01-1 0V9H6.414A1.5 1.5 0 015 7.586V7H3.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H5V3.914A1.5 1.5 0 016.414 3H8V1.5a.5.5 0 01.5-.5zM3 1a1 1 0 011-1h8a1 1 0 011 1v1H3V1zm0 13a1 1 0 01-1-1V5h12v8a1 1 0 01-1 1H3z" />
  </svg>
);