import React from "react";
import {
  Center,
  Card,
  CardBody,
  Heading,
  Text,
  Stack,
  Image,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import "../../styles/Home/QualitySlides.css";

const QualitySlides = () => {
  const slides = [
    {
      heading: "Get Quality Candidates",
      text: "Sugriva is dedicated to providing top-tier talent tailored to your specific needs. Our thorough screening and vetting process ensures that only the most qualified and committed candidates are presented to you.",
      image:
        "https://images-platform.99static.com/4WyNZ8MHpic0QHJquKi9e2eVwA4=/0x246:1240x1486/500x500/top/smart/99designs-contests-attachments/68/68170/attachment_68170462",
    },
    {
      heading: "Rigorous Vetting Process",
      text: "We conduct comprehensive background checks, skill assessments, and behavioral interviews to ensure candidates not only meet your requirements but also fit seamlessly into your company culture.",
      image:
        "https://cdn-images.zety.com/images/zety/landings/static/check-your-resume@3x.png",
    },
    {
      heading: "Guaranteed Commitment",
      text: "Our unique guarantee means that every candidate we place is committed to joining your team. If a candidate backs out, we provide a replacement at no additional cost.",
      image:
        "https://365datascience.com/resources/blog/2020-12-data-scientist-resume-sample-template-1024x559.jpg",
    },
  ];

  return (
    <div className="quality-slides-section">
      <Grid gap={5}>
        {slides.map((slide, index) => (
          <GridItem key={index} w="100%">
            <Center color="#BA654F">
              <Card
                direction={{ base: "column", md: "row" }}
                overflow="hidden"
                variant="elevated"
                width="90%"
   
                marginY={{ base: "1rem", md: "2rem" }} // Adjusted margin
                boxShadow="lg" // Added shadow for better visual distinction
              >
                <Image
                  objectFit="cover"
                  maxW={{ base: "100%", md: "300px" }}
                  maxH={{ base: "200px", md: "auto" }} // Adjusted image height for mobile
                  src={slide.image}
                  alt={slide.heading}
                />
                <Stack flex="1" padding="1">
                  <CardBody>
                    <Heading size={{ base: "md", md: "lg" }}>{slide.heading}</Heading>
                    <Text py="2" fontSize={{ base: "sm", md: "md" }}>
                      {slide.text}
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
            </Center>
          </GridItem>
        ))}
      </Grid>
    </div>
  );
};

export default QualitySlides;