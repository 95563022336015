import React from 'react';
import { EmailIcon } from "@chakra-ui/icons";
import {
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";

import '../../styles/Home/Footer.css';

const Footer = () => {
  return (
    <Box className="footer">
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        px="2rem"
        py="3rem"
        textAlign="center"
        gap={2}
      >
        <Text fontWeight="bold" fontSize="xl" color="white" mb={2}>
          <EmailIcon boxSize={5} mr={2} /> business@sugriva.in
        </Text>
        <Text fontSize="md" color="white">
          &copy; 2024 Sugriva. All Rights Reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;