// src/components/Dashboard/JobTile.js

import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import '../../styles/Dashboard/JobTile.css'; // Import specific CSS for the JobTile

const JobTile = ({ location, status }) => {
  return (
    <Box className="job-card">
      <Text className={`status ${status === 'Active' ? 'active' : 'not-active'}`}>{status}</Text>
      <Text fontSize="lg" fontWeight="bold" className="job-title">Senior Software Engineer</Text>
      <Text className="job-description">Develop and maintain scalable web applications using modern technologies.</Text>
      <Text className="job-location">
        <span className="location-icon">📍</span> {location}
      </Text>
      <Text className="job-date">Posted on June 15, 2024, <Link href="#" className="job-details">View Details</Link></Text>
    </Box>
  );
};

export default JobTile;