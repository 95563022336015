import React from 'react';
import {
    Button,
    Card,
    CardBody,
    Center,
    Heading,
    Text,
} from "@chakra-ui/react";
import '../../styles/Home/RecruitmentSection.css'; // Import the CSS file

const RecruitmentSection = () => {
    return (
        <Card variant={"unstyled"} className="recruitment-section">
            <CardBody>
                <Center>
                    <div>
                        <Text className="sub-heading">
                            Struggling to fill key roles?
                        </Text>
                        <Heading as="h1" className="main-heading">
                            Elevate Your Recruitment with <span className="heading">Sugriva.in</span>
                        </Heading>
                        <Text className="description">
                            Get the best candidates in just days, not months. <br /> No salary negotiation, no offer hopping.
                        </Text>
                        <a href="/editor">
                            <Button className="cta-button">
                                Begin The Interview
                            </Button>
                        </a>
                    </div>
                </Center>
            </CardBody>
        </Card>
    );
};

export default RecruitmentSection;