import React, { useState, useEffect, useCallback } from 'react';
import Editor from '@monaco-editor/react';
import axios from 'axios';
import Peer from 'peerjs';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import '../../styles/Editor/Editor.css';
import { FaSave } from 'react-icons/fa';

const socket = io('https://sugriva.in'); // Connect to the Socket.IO server

const templates = {
  python: `# Start coding in Python
def main():
    print("Hello, world!")

if __name__ == "__main__":
    main()`,
  cpp: `// Start coding in C++
#include <iostream>

int main() {
    std::cout << "Hello, world!" << std::endl;
    return 0;
}`,
  java: `// Start coding in Java
public class Main {
    public static void main(String[] args) {
        System.out.println("Hello, world!");
    }
}`,
  c: `// Start coding in C
#include <stdio.h>

int main() {
    printf("Hello, world!\\n");
    return 0;
}`,
  ruby: `# Start coding in Ruby
puts "Hello, world!"`,
  go: `// Start coding in Go
package main

import "fmt"

func main() {
    fmt.Println("Hello, world!")
}`,
  php: `<?php
// Start coding in PHP
echo "Hello, world!";
?>`,
  rust: `// Start coding in Rust
fn main() {
    println!("Hello, world!");
}`,
  swift: `// Start coding in Swift
print("Hello, world!")`,
  kotlin: `// Start coding in Kotlin
fun main() {
    println("Hello, world!")
}`,
  typescript: `// Start coding in TypeScript
function main() {
    console.log("Hello, world!");
}
main();`,
  scala: `// Start coding in Scala
object Main extends App {
    println("Hello, world!")
}`,
  r: `# Start coding in R
print("Hello, world!")`,
};

const languageToCompilerId = {
  python: 116,       // Python 3.8.2
  cpp: 44,           // C++ (GCC 9.2.0)
  java: 10,          // Java (OpenJDK 13.0.1)
  c: 11,             // C (GCC 9.2.0)
  ruby: 17,          // Ruby 2.7.0
  go: 114,           // Go 1.14.1
  php: 29,           // PHP 7.4.4
  rust: 93,          // Rust 1.42.0
  swift: 85,         // Swift 5.2.1
  kotlin: 87,        // Kotlin 1.3.70
  typescript: 74,    // TypeScript 3.8.3
  scala: 39,         // Scala 2.13.2
  r: 80,             // R 4.0.0
};


const CodeEditor = () => {
  const [language, setLanguage] = useState('python'); // Default language set to Python
  const [code, setCode] = useState(templates['python']); // Default code template set to Python
  const [output, setOutput] = useState('');
  const [peerId, setPeerId] = useState(null);
  const [peer, setPeer] = useState(null);
  const [remotePeerId, setRemotePeerId] = useState('');
  const [interviewMode, setInterviewMode] = useState(null); // null, 'start', or 'join'
  const [displayName, setDisplayName] = useState(""); // State for display name
const [isDisplayNameValid, setIsDisplayNameValid] = useState(false); // Track validity
const [localDisplayName, setLocalDisplayName] = useState(""); // State for local display name
const [remoteDisplayName, setRemoteDisplayName] = useState("Display Name"); // Default remote display name
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false); // State to track if user is logged in

useEffect(() => {
  // Check for JWT token in local storage
  const token = localStorage.getItem('jwtToken');
  if (token) {
    // Optionally, you can decode and verify the token here
    setLoggedIn(true); // If token exists, consider the user logged in
  }
}, []); // Runs only once when the component is mounted

  const callPeer = useCallback((peerIdToCall) => {
    const peerId = peerIdToCall || remotePeerId;
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        document.getElementById('localVideo').srcObject = stream;
        const call = peer.call(peerId, stream);

        call.on('stream', (remoteStream) => {
          document.getElementById('remoteVideo').srcObject = remoteStream;
        });

        call.on('close', () => {
          handleCallEnd();
        });
      })
      .catch((error) => {
        console.error('Error accessing media devices.', error);
      });
  }, [peer, remotePeerId]);

  const startInterview = () => {
    if (!loggedIn) {
      alert("Please log in to start an interview.");
      return;
    }

    if (!isDisplayNameValid) {
      alert("Please enter a display name.");
      return;
    }
    
    setLocalDisplayName(displayName); 
    const newPeer = new Peer();
  
    newPeer.on('open', (id) => {
      setPeerId(id);
      setInterviewMode('start');
      // Role: "interviewer"
      socket.emit('joinRoom', { roomId: id, displayName: displayName, role: 'interviewer' });
  
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((stream) => {
          document.getElementById('localVideo').srcObject = stream;
        })
        .catch((error) => {
          console.error('Error accessing media devices:', error);
        });
    });
  
    newPeer.on('call', (call) => {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then((stream) => {
          document.getElementById('localVideo').srcObject = stream;
          call.answer(stream);
          call.on('stream', (remoteStream) => {
            document.getElementById('remoteVideo').srcObject = remoteStream;
          });
          call.on('close', () => {
            handleCallEnd();
          });
        })
        .catch((error) => {
          console.error('Error accessing media devices:', error);
        });
    });
  
    setPeer(newPeer);
  };
  
  const joinInterview = (input) => {
    if (!isDisplayNameValid) {
      alert("Please enter a display name.");
      return;
    }
    if (!input) return;
  
    const interviewId = input.includes('?interview=') 
      ? new URL(input).searchParams.get('interview') 
      : input;
  
    const newPeer = new Peer();
  
    newPeer.on('open', async () => {
      setRemotePeerId(interviewId);
      setPeer(newPeer);
      setLocalDisplayName(displayName); // Set the local display name
  
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        document.getElementById('localVideo').srcObject = stream;
  
        const call = newPeer.call(interviewId, stream);
        call.on('stream', (remoteStream) => {
          document.getElementById('remoteVideo').srcObject = remoteStream;
        });
  
        call.on('close', () => {
          handleCallEnd();
        });
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    });
  
    // Role: "interviewee"
    socket.emit('joinRoom', { roomId: interviewId, displayName: displayName, role: 'interviewee' });
  };

  useEffect(() => {
    socket.on('setLocalDisplayName', ({ displayName }) => {
      setLocalDisplayName(displayName);  // Set your own display name
    });
  
    socket.on('setRemoteDisplayName', ({ displayName }) => {
      setRemoteDisplayName(displayName);  // Set the other participant's display name
    });
  
    socket.on('requestDisplayName', ({ from }) => {
      // Send your display name to the other participant who requested it
      socket.emit('sendDisplayName', { to: from, displayName });
    });
  
    return () => {
      socket.off('setLocalDisplayName');
      socket.off('setRemoteDisplayName');
      socket.off('requestDisplayName');
    };
  }, [displayName]);

  useEffect(() => {
    // Listen for code updates from other clients
    socket.on('codeUpdate', (updatedCode) => {
      setCode(updatedCode);
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.off('codeUpdate');
    };
  }, []);

  const handleSaveCode = async () => {
    try {
      const response = await axios.post('https://sugriva.in/api/save-code', {
        interviewId: peerId || remotePeerId,
        code,
      });
      alert('Code saved successfully!');
    } catch (error) {
      console.error('Error saving code:', error);
      alert('Failed to save code.');
    }
  };

  const handleEditorChange = (value) => {
    setCode(value);
    socket.emit('codeChange', { roomId: peerId || remotePeerId, code: value });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    setCode(templates[newLanguage]);
    setOutput(''); // Clear output when changing languages
  };

  const runCode = async () => {
    try {
      const { data } = await axios.post('https://sugriva.in/api/submit', {
        language: languageToCompilerId[language],
        source: code,
      });

      const submissionId = data.id;
      const result = await pollForResult(submissionId);
      setOutput(result);
    } catch (error) {
      console.error('Error running code:', error);
      setOutput('An error occurred while executing the code.');
    }
  };

  const pollForResult = async (submissionId) => {
    const endpoint = `https://sugriva.in/api/result/${submissionId}`;
    while (true) {
      const { data } = await axios.get(endpoint);
      if (data.executing) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } else {
        if (data.result.streams.output && data.result.streams.output.uri) {
          const outputResponse = await axios.get(`https://sugriva.in/api/fetch-output`, {
            params: { uri: data.result.streams.output.uri }
          });
          return outputResponse.data;
        } else if (data.result.streams.cmpinfo && data.result.streams.cmpinfo.uri) {
          const compileInfoResponse = await axios.get(`https://sugriva.in/api/fetch-output`, {
            params: { uri: data.result.streams.cmpinfo.uri }
          });
          return compileInfoResponse.data;
        } else {
          return 'No output received or there was a runtime/compilation error.';
        }
      }
    }
  };

  const handleCallEnd = () => {
    // Stop the local video stream
    const localVideo = document.getElementById('localVideo');
    if (localVideo.srcObject) {
      localVideo.srcObject.getTracks().forEach(track => track.stop());
      localVideo.srcObject = null;
    }

    // Stop the remote video stream
    const remoteVideo = document.getElementById('remoteVideo');
    if (remoteVideo.srcObject) {
      remoteVideo.srcObject = null;
    }
  };

  const copyInterviewLink = () => {
    const interviewLink = `${window.location.origin}${location.pathname}?interview=${peerId}`;
    navigator.clipboard.writeText(interviewLink);
    alert("Interview link copied to clipboard!");
  };



  return (
    <div className="editor-container">
      {!interviewMode ? (
        <div className="interview-options">
        <input
          type="text"
          placeholder="Enter Display Name"
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
            setIsDisplayNameValid(e.target.value.trim().length > 0);
          }}
          className="display-name-input"
        />
        <button onClick={startInterview}>
          Start New Interview
        </button>
        <button onClick={() => setInterviewMode('join')}>
          Join an Interview
        </button>
      </div>
      ) : interviewMode === 'start' ? (
        <div className="peer-id">
          <div className="peer-id-display">
            <span>Your Peer ID: {peerId}</span>
            <button onClick={copyInterviewLink}>Copy Interview Link</button>
          </div>
        </div>
      ) : (
        <div className="join-interview">
          <input
            type="text"
            placeholder="Enter Peer ID / Interview Link"
            value={remotePeerId}
            onChange={(e) => setRemotePeerId(e.target.value)}
          />
          <button onClick={() => joinInterview(remotePeerId)}>
            Join Interview
          </button>
        </div>
      )}
  
      {interviewMode && (
        <div className="editor-output-container">
          <div className="editor-header">
            <h3>Code Editor</h3>
            <div className="language-selector">
              <label htmlFor="language">Language: </label>
              <select id="language" value={language} onChange={handleLanguageChange}>
                <option value="python">Python</option>
                <option value="cpp">C++</option>
                <option value="java">Java</option>
                <option value="c">C</option>
                <option value="ruby">Ruby</option>
                <option value="go">Go</option>
                <option value="php">PHP</option>
                <option value="rust">Rust</option>
                <option value="swift">Swift</option>
                <option value="kotlin">Kotlin</option>
                <option value="typescript">TypeScript</option>
                <option value="scala">Scala</option>
                <option value="r">R</option>
              </select>
            </div>
          </div>
  
          <div className="editor-video-container">
            <div className="editor-section">
            <div className="save-icon-container">
      <FaSave onClick={handleSaveCode} className="save-icon" />
    </div>
              <Editor
                height="60vh"
                width="100%"
                language={language}
                value={code}
                theme="vs-dark"
                options={{
                  fontSize: 16,
                  minimap: { enabled: false },
                  scrollbar: {
                    verticalScrollbarSize: 8,
                    horizontalScrollbarSize: 8,
                  },
                }}
                onChange={handleEditorChange}
              />
            </div>
  
            <div className="video-container">
  <div className="video-wrapper">
    <video id="localVideo" autoPlay muted></video>
    <p className="display-name-label">{localDisplayName}</p>
  </div>
  <div className="video-wrapper">
    <video id="remoteVideo" autoPlay></video>
    <p className="display-name-label">{remoteDisplayName}</p>
  </div>
</div>
          </div>
  
          <button onClick={runCode} className="run-button">Run Code</button>
  
          <div className="output-section">
            <div className="output-panel">
              <h4>Output</h4>
              <pre>{output}</pre>
            </div>
          </div>


        </div>
      )}
    </div>
  );
};

export default CodeEditor;
