// src/components/Dashboard/Sidebar.js

import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import '../../styles/Dashboard/Sidebar.css'; // Import specific CSS for the sidebar
import { HomeIcon, DashboardIcon, JobsIcon, CMIIcon } from './Icons'; // Assuming you have these icons

const Sidebar = ({ isOpen, onClose }) => {
  return (
    <Box className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="logo">
        <Link href="/" style={{ cursor: 'pointer', textDecoration: 'none' }}>
          <Text fontSize="1.5rem" fontWeight="bold" color="white">
            Sugriva.in
          </Text>
        </Link>
        <hr className="divider" />
      </div>
      <ul className="menu">
        <li><Link href="/dashboard" className="menu-item"><span className="menu-icon"><HomeIcon /></span> Home</Link></li>
        <li><Link href="/dashboard" className="menu-item"><span className="menu-icon"><DashboardIcon /></span> Dashboards</Link></li>
        <li><Link href="/dashboard" className="menu-item"><span className="menu-icon"><JobsIcon /></span> Jobs</Link></li>
        <li><Link href="/dashboard" className="menu-item"><span className="menu-icon"><CMIIcon /></span> CMI</Link></li>
      </ul>
      <button className="close-button" onClick={onClose}>×</button>
    </Box>
  );
};

export default Sidebar;